exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */),
  "component---src-pages-sitios-web-tsx": () => import("./../../../src/pages/sitios-web.tsx" /* webpackChunkName: "component---src-pages-sitios-web-tsx" */),
  "component---src-pages-software-personalizado-tsx": () => import("./../../../src/pages/software-personalizado.tsx" /* webpackChunkName: "component---src-pages-software-personalizado-tsx" */)
}

